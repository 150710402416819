import React from "react"
import styles from "./styles"
import { useTheme } from "@mui/material/styles"
import {
    Box,
    Grid,
	Typography,
    useMediaQuery
} from "@mui/material"
import imageLarge from "../../../static/Images/ballantines/05.jpeg"

import Image from '../Image';

const DigitalTransformation = ({ titleOne, titleTwo, digital_image, paragraph, button }) => {
	const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box css={styles(theme)}>
			<Grid className="container" container direction={mobile ? "column" : "row"} nowrap>
                <Grid className="container-images" item container>
                    <Grid className="images-large" item>
                        <Image src={digital_image} alt="imageLarge" />
                    </Grid>
                    {/* <Grid className="images-small" item container>
                        <img src={imageOne} alt="imageOne" />
                        <img src={imageTwo} alt="imageTwo" />
                        <img src={imageThree} alt="imageThree" />
                    </Grid> */}
                </Grid>
                <Grid className="container-info" item container alignItems="flex-start">
                    <Typography component={"h1"} className="info-title">
                        <span style={{ color: "#E95659" }}>{titleOne}</span>{' '}{titleTwo}
                    </Typography>
                    <Typography component={"p"} className="info-paragraph" pt={4}>
                        {paragraph}
                    </Typography>
                    {/* <Button className="info-btn">
                        {button}
                    </Button> */}
                </Grid>
            </Grid>
		</Box>
	)
}

export default DigitalTransformation