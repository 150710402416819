import {css as styled} from "@emotion/react"

const styling = (theme) => styled`

    .video-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #ea5758;
        min-height: 30vh;
        max-width: 100vw;
        margin: auto;
        text-align: center;
        padding-top: ${theme.spacing(10)};
        padding-bottom: ${theme.spacing(15)};

        ${theme.breakpoints.down('sm')} {
            min-height: auto;
            margin-bottom: -4px;
            padding: 0;
        }
    }

    .content-image {
        position: relative;
        max-width: 100%;
        width: 100%;
        max-height: 60%;
        height: 100%;
        text-align: center;
        margin: auto;

        ${theme.breakpoints.down('lg')} {
            max-width: 100%;
            max-height: 40%;
        }
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #F6D741;
        opacity: 0.96;
        top: 0;
        left: 0;
    }

    .video {
        position: relative;
        max-width: 100%;
        height: auto;
        outline: none;
        border: none;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;
    }

    .text-container {
        max-width: 50%;
        margin: auto;
        text-align: center;

        ${theme.breakpoints.down('md')} {
            max-width: 90%;
        }
    }

    h4 {
        color: #fff;
        font-size: 2rem;
        font-weight: 200;
        opacity: 0.8;
        z-index: 100;

        ${theme.breakpoints.down('md')} {
            font-size: 1.2rem;
        }
    }

    h5 {
        color: #161B45;
        font-size: 2rem;
        font-weight: 600;
        opacity: 0.8;
        z-index: 100;

        ${theme.breakpoints.down('md')} {
            font-size: 1.2rem;
        }
    }

`

export default styling