import React from "react"
import styles from "./styles"
import { useTheme } from "@mui/material/styles"
import {
    Box,
	Container,
	Typography,
    useMediaQuery
} from "@mui/material"
import video from "../../../static/video/home-video.mp4"

const VideoContent = ({videoText, lineOne, lineTwo}) => {
	const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('lg'))
    const phone = useMediaQuery(theme.breakpoints.down(680))

	return (
		<div css={styles(theme)}>
			<Container className="video-content">
                <Box className="content-image">
                    <video className="video" autoPlay autoplay muted playsInline playsinline webkit-playsinline={true} loop width={phone ? "100%" : mobile ? "640" : "1080"} height={phone ? "100%" : mobile ? "500" : "720"}>
                        <source src={video} type="video/mp4" />
                    </video>
                </Box>
                <Container className="text-container" pt={4}>
                    {/* <Typography pb={4} component={"h4"}>{videoText}</Typography> */}
                    <Typography component={"h4"} className="content-text">{lineOne}</Typography>
                    <Typography component={"h5"} className="content-subtext">{lineTwo}</Typography>
                </Container>
            </Container>
		</div>
	)
}

export default VideoContent