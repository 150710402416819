import { css as styled } from "@emotion/react";

const styling = (theme) => styled`

    @font-face {
        font-family: 'Comfortaa';
        src: url('/fonts/Comfortaa-Medium.ttf') format('ttf');
    }

    .container {
        width: 100vw;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;

        ${theme.breakpoints.down('md')} {
            padding: ${theme.spacing(0)};
            flex-flow: column-reverse;
        }
    }

    .container-images {
        max-width: 55%;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: ${theme.spacing(15, 0, 15, 15)};

        ${theme.breakpoints.up(2000)} {
            padding: ${theme.spacing(15, 0, 15, 25)};
        }

        ${theme.breakpoints.down('md')} {
            padding: 0;
            max-width: 100%;
        }
    }

    .container-info {
        align-content: flex-start;
        max-width: 50%;
        width: 100%;
        padding: ${theme.spacing(15)};
        padding-left: ${theme.spacing(10)};
        position: relative;
        top: -15px;

        ${theme.breakpoints.up(2000)} {
            padding: ${theme.spacing(15, 25)};
        }

        ${theme.breakpoints.down('md')} {
            padding: ${theme.spacing(6, 4)};
            max-width: 100%;
        }

        ${theme.breakpoints.down('500')} {
            padding: 60px 40px;
        }
    }

    .images-large {
        width: 100%;
        position: relative;
        left: -8px;

        ${theme.breakpoints.down('md')} {
            left: 0;
        }
    }

    .images-large img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }

    .images-small {
        justify-content: space-between;
        width: 100%;
    }

    .images-small img {
        width: 32%;
        padding-top: ${theme.spacing(2)};
    }

    .info-btn {
        width: 200px;
        height: 50px;
        background-color: #EB5558;
        border-radius: 50px;
        color: #fff;
        text-transform: capitalize;
        font-family: 'Comfortaa', sans-serif !important;
        font-size: 0.8rem;
        ffont-weight: 800 !important;
    }

    h1 {
        font-size: 3rem;
        line-height: 70px;
        color: #000;

        ${theme.breakpoints.up(2000)} {
            font-size: 6rem;
            line-height: 130px;
        }

        ${theme.breakpoints.down('md')} {
            line-height: 50px;
            font-size: 2rem;
        }
    }

    p {
        padding-bottom: ${theme.spacing(4)};
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 800 !important;
        font-size: 1rem;
        line-height: 2.2rem !important;
        color: #000;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.4rem;
            line-height: 50px !important;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 0.9rem;
            line-height: 1.8rem !important;
        }
    }

`;

export default styling;
