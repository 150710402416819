import {css as styled} from "@emotion/react"

const styling = (theme) => styled`

    @font-face {
        font-family: 'Comfortaa';
        src: url('/fonts/Comfortaa-Medium.ttf') format('ttf');
    }

    ${theme.breakpoints.up('xl')} {
        min-height: auto;
    }

    .container {
        ${theme.breakpoints.up('xl')} {
            min-height: auto;
        }
    }

    .container-item {
        align-content: flex-start;
        background-color: #1F212D;
        max-width: 50%;
        width: 100%;
        padding: ${theme.spacing(16, 10, 10, 15)};

        ${theme.breakpoints.up(2000)} {
            padding: ${theme.spacing(16, 25)};
        }

        ${theme.breakpoints.down('lg')} {
            max-width: 100%;
        }

        ${theme.breakpoints.down('md')} {
            padding: ${theme.spacing(6, 4)};
        }

        ${theme.breakpoints.down(500)} {
            padding: 60px 40px;
        }
    }

    .container-image {
        max-width: 50%;
        width: 100%;

        ${theme.breakpoints.down('lg')} {
            max-width: 100%;
            min-height: 40vh;
        }
    }

    .image {
        width: inherit;
        height: 100%;
        object-fit: cover;

        ${theme.breakpoints.down('lg')} {
            max-width: 100%;
            min-height: 40vh;
        }
    }

    .para1, .para2 {
        max-width: 90%;
        line-height: 1.8rem;

        ${theme.breakpoints.down(500)} {
            max-width: 100%;
        }
    }

    .btn-link {
        text-decoration: none;
    }

    .btn {
        width: 200px;
        height: 50px;
        background-color: #EB5558;
        border-radius: 50px;
        color: #fff;
        text-transform: none;
        font-family: 'Comfortaa', sans-serif !important;
        font-size: 0.9rem;
        font-weight: 800 !important;
        padding-top: 8px;
        padding-bottom: 4px;
        transition: 0.3s ease;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.1rem;
            width: 240px;
            height: 70px;
        }
    }

    .btn:hover {
        background-color: #222532;
    }

    h1 {
        font-size: 3rem;
        line-height: 70px;
        color: #fff;

        ${theme.breakpoints.up(2000)} {
            font-size: 6rem;
            line-height: 130px;
            max-width: 90%;
        }

        ${theme.breakpoints.down('md')} {
            line-height: 50px;
            font-size: 2rem;
        }
        
    }

    p {
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 400 !important;
        font-size: 1rem;
        line-height: 2.2rem !important;
        color: #fff;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.4rem;
            line-height: 50px !important;
            max-width: 60%;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 0.9rem;
            line-height: 1.8rem !important;
        }

    }

`

export default styling