import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import HeroLower from '../components/HeroLower';
import VideoContent from '../components/VideoContent';
import CxBattleground from '../components/CxBattleground';
import CreativityInsight from '../components/CreativityInsight';
import DigitalTransformation from '../components/DigitalTransformation';
import SpeakToUs from '../components/SpeakToUs';
import SEO from '../components/SEO';

// eslint-disable-next-line
export const HomeTemplate = ({
	id,
	hero,
	heroLower,
	videoContent,
	cxBattleground,
	creativityInsight,
	digitalTransformation,
	speakToUs
}) => {
	
	return (
		<div style={{ overflowX: 'hidden' }}>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
				image={`${id}.jpg`}
			/>
			<Hero {...hero} />
			<HeroLower {...heroLower} />
			{/*<div style={{background: '#1c1b46'}}>*/}
			{/*	<Animation/>*/}
			{/*</div>*/}
			<VideoContent {...videoContent} />
			<CxBattleground {...cxBattleground} />
			<CreativityInsight {...creativityInsight} />
			<DigitalTransformation {...digitalTransformation} />
			<SpeakToUs {...speakToUs} />
		</div>
	);
};

HomeTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	hero: PropTypes.object,
	heroLower: PropTypes.object,
	videoContent: PropTypes.object,
	cxBattleground: PropTypes.object,
	creativityInsight: PropTypes.object,
	digitalTransformation: PropTypes.object,
	speakToUs: PropTypes.object
};

const Home = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<HomeTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				hero={frontmatter.hero}
				heroLower={frontmatter.heroLower}
				videoContent={frontmatter.videoContent}
				cxBattleground={frontmatter.cxBattleground}
				creativityInsight={frontmatter.creativityInsight}
				digitalTransformation={frontmatter.digitalTransformation}
				speakToUs={frontmatter.speakToUs}
			/>
		</Layout>
	);
};

Home.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object
		})
	})
};

export default Home;

export const pageQuery = graphql`
	query Home {
		markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
			id
			frontmatter {
				hero {
					logoImage
					showLogo
					heroColor
					lineOne
					lineOneColor
					lineOneFill
					lineTwo
					lineTwoColor
					lineTwoFill
					inline
				}
				heroLower {
					text
				}
				videoContent {
					videoText
					lineOne
					lineTwo
				}
				cxBattleground {
					cx
					lineOne
					lineTwo
					cx_image {
						childImageSharp {
							gatsbyImageData(width: 2000, quality: 100)
						}
					}
					paragraph
					button
				}
				creativityInsight {
					titleOne
					titleTwo
					titleThree
					titleFour
					creativity_image {
						childImageSharp {
							gatsbyImageData(width: 2000, quality: 100)
						}
					}
					paragraphOne
					paragraphTwo
					button
				}
				digitalTransformation {
					titleOne
					titleTwo
					digital_image {
						childImageSharp {
							gatsbyImageData(width: 2000, quality: 100)
						}
					}
					paragraph
					button
				}
				speakToUs {
					speakToUs_image {
						childImageSharp {
							gatsbyImageData(width: 2000, quality: 100)
						}
					}
					title
					paragraph
					button
				}
				footer {
					email
					phone
					address
					lineOne
					lineTwo
					linkOne
					linkTwo
					copyright
				}
			}
		}
	}
`;
