import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Link } from 'gatsby';
import {Button, Grid, Typography, useMediaQuery } from '@mui/material';
import image from '../../../static/Images/nars/selfridges03.jpg';

import Image from '../Image';

const CreativityInsight = ({
	titleOne,
	titleTwo,
	titleThree,
	titleFour,
	creativity_image,
	paragraphOne,
	paragraphTwo,
	button
}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<div css={styles(theme)}>
			<Grid className="container" container direction={mobile ? 'column' : 'row'} nowrap>
				<Grid className="container-item" item container>
					<Typography sx={{ pb: 4 }} className="title" component={'h1'}>
						{titleOne} <span style={{ color: '#E95659' }}>{titleTwo}</span> {titleThree}{' '}
						<span style={{ color: '#E95659' }}>{titleFour}</span>
					</Typography>
					<Typography sx={{ pb: 4 }} className="para1">
						{paragraphOne}
					</Typography>
					<Typography sx={{ pb: 8 }} className="para2">
						{paragraphTwo}
					</Typography>
					<Link to="/services" className="btn-link">
						<Button className="btn">{button}</Button>
					</Link>
				</Grid>
				<Grid className="container-image" item>
					<Image className="image" src={creativity_image} alt="image" />
				</Grid>
			</Grid>
		</div>
	);
};

export default CreativityInsight;
