import {css as styled} from "@emotion/react"

const styling = (theme) => styled`

    @font-face {
        font-family: 'Comfortaa';
        src: url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&display=swap');
    }

    @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&display=swap');

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: white;
        max-width: 100vw;
        width: 100%;
        padding: ${theme.spacing(15, 15)};

        ${theme.breakpoints.up(2000)} {
            min-height: auto;
        }

        ${theme.breakpoints.down('lg')} {
            max-height: 100%;
        }

        ${theme.breakpoints.down('md')} {
            justify-content: flex-start;
            padding: 0;
            max-height: 100%;
        }
    }

    .container-info {
        position: relative;
        top: -10px;
        max-width: 90%;
        width: 90%;
        text-align: left;
        padding-left: 0;
        padding-right: ${theme.spacing(10)};

        ${theme.breakpoints.up(2000)} {
            padding: ${theme.spacing(0, 10)};
        }

        ${theme.breakpoints.down('lg')} {
            padding: ${theme.spacing(0, 6, 6, 0)};
        }

        ${theme.breakpoints.down('md')} {
            height: 60%;
            max-width: 100%;
            padding: ${theme.spacing(6, 0)};
            padding-bottom: 0;
        }

        ${theme.breakpoints.down('500')} {
            margin: 0;
            padding: 60px 20px;
        }
    }

    .container-image {
        max-width: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 0;
        padding: 0;

        ${theme.breakpoints.down('lg')} {
            max-height: 100%;
        }

        ${theme.breakpoints.down('md')} {
            max-width: 100%;
            padding-top: ${theme.spacing(4)};
        }
    }

    .info-header {
        ${theme.breakpoints.up(2000)} {
            font-size: 6rem;
            line-height: 130px;
        }

        ${theme.breakpoints.down('md')} {
            font-size: 3rem;
            line-height: 3.6rem;
        }

        ${theme.breakpoints.down(480)} {
            font-size: 2rem;
            line-height: 3rem;
        }
    }

    .info-text {
        width: 100%;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.4rem;
            line-height: 50px !important;
            max-width: 60%;
        }
    }

    .button-link {
        text-decoration: none;
    }

    .info-btn {
        background-color: #ea5758;
        color: white;
        border-radius: 50px;
        font-family: 'Comfortaa', sans-serif !important;
        font-size: 0.9rem;
        font-weight: 800 !important;
        width: 200px;
        height: 50px;
        text-transform: none;
        margin-top: ${theme.spacing(5)};
        padding-bottom: 4px;
        transition: 0.3s ease;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.1rem;
            width: 240px;
            height: 70px;
        }
    }

    .info-btn:hover {
        background-color: #222532;
    }

    .image-left {
        width: 100%;
        object-fit: cover;
        max-height: 100vh;

        ${theme.breakpoints.down('md')} {
            max-height: 100%;
        }
    }

    .image-right {
        max-width: 30%;
        max-height: 500px;
        object-fit: cover;
        object-position: -40px 0;
        padding-left: ${theme.spacing(2)};
    }

    .text-container {
        max-width: 50%;
        margin: auto;
        text-align: center;
    }

    span {
        color: #EA5758;
    }

    h1 {
        font-size: 3rem;
        line-height: 70px;
        color: #000;

        ${theme.breakpoints.up(2000)} {
            font-size: 6rem;
            line-height: 130px;
            max-width: 90%;
        }

        ${theme.breakpoints.down('md')} {
            line-height: 50px;
            font-size: 2rem;
        }
    }

    p {
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 800 !important;
        font-size: 1rem;
        line-height: 2.2rem !important;
        color: #000;

        ${theme.breakpoints.up(2000)} {
            font-size: 1.4rem;
            line-height: 50px !important;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 0.9rem;
            line-height: 1.8rem !important;
        }
    }

`

export default styling