import React from "react"
import styles from "./styles"
import { useTheme } from "@mui/material/styles"
import { Link } from "gatsby"
import {
    Box,
    Button,
	Container,
	Typography,
    useMediaQuery
} from "@mui/material"
import imageLeft from "../../../static/Images/opi/stanstedretail02.jpg"
import imageRight from "../../../static/Images/opi/stanstedretail03.jpg"

import Image from '../Image';

const CxBattleground = ({cx, cx_image, lineOne, lineTwo, paragraph, button, inline}) => {
	const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    console.log(cx_image)

	return (
		<Box css={styles(theme)}>
			<Container className="container" sx={{ flexDirection: mobile ? "column" : "row" }}>
                <Container className="container-info">
                    {inline ?
                        <Typography component={"h1"} className="info-header"><span>{cx}</span> {lineOne}{' '}{lineTwo}</Typography>
                    :
                    <>
                        <Typography component={"h1"} className="info-header"><span>{cx}</span> {lineOne}</Typography>
                        <Typography component={"h1"} className="info-header" pb={4}>{lineTwo}</Typography>
                    </>
                    }
                    <Typography component={"p"} className="info-text">{paragraph}</Typography>
                    <Link to="/approach" className="button-link">
                        <Button className="info-btn">{button}</Button>
                    </Link>
                </Container>
                <Container className="container-image">
                    <Image className="image-left" src={cx_image} alt="image-left" />
                    {/* <img className="image-right" src={imageRight} alt="image-right" /> */}
                </Container>
            </Container>
		</Box>
	)
}

export default CxBattleground
