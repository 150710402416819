import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Typography, useMediaQuery } from '@mui/material';

const Hero = ({ heroColor, logoImage, showLogo, lineOne, lineOneFill, lineOneColor, lineTwo, lineTwoFill, lineTwoColor, inline }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const xl = useMediaQuery(theme.breakpoints.up(2000));

	return (
		<div style={{ backgroundColor: heroColor }} css={styles(theme)}>
			<Container
				className={`hero ${inline ? 'hero-inline' : ''}`}
				// ${showLogo ? 'hero-move-up' : ''}
			>
				{!!showLogo && (
					<>
						<img className="hero-logo" style={{ marginBottom: xl ? '6rem' : '3rem', transform: xl ? 'scale(1.5)' : 'scale(1)' }} src="/img/play-coral.svg" width={xl ? "250" : "160"} alt="Play." />
						{/* <span className="logo-dot">.</span> */}
					</>
				)}

				{/* <Typography component={"h2"} className={subHeading === 'Play.' ? 'play' : ''}>{subHeading}</Typography> */}
				{mobile ? (
					<Typography className="hero-text" component={'h2'}>
						<span
							style={{ color: lineOneColor }}
							className={`hero-text ${!lineOneFill ? 'line-one-fill' : ''} ${
								!showLogo ? 'line-large' : ''
							}`}
						>
							{lineOne}
						</span>{' '}
						<span
							style={{ color: lineTwoColor }}
							className={`hero-text ${!lineTwoFill ? 'line-two-fill' : ''} ${
								!showLogo ? 'line-large' : ''
							}`}
						>
							{lineTwo}
						</span>
					</Typography>
				) : (
					<>
						<Typography
							sx={{ color: lineOneColor }}
							className={`hero-text ${!lineOneFill ? 'line-one-fill' : ''} ${
								!showLogo ? 'line-large' : ''
							}`}
							component={showLogo ? 'h1' : 'h2'}
						>
							{lineOne}
						</Typography>
						<Typography
							sx={{ color: lineTwoColor }}
							className={`hero-text ${!lineTwoFill ? 'line-two-fill' : ''} ${
								!showLogo ? 'line-large' : ''
							}`}
							component={'h2'}
						>
							{lineTwo}
						</Typography>
					</>
				)}
			</Container>
			<Container
				onClick={() => {
					document.getElementById('hero-lower').scrollIntoView({ behavior: 'smooth' });
				}}
				className="hero-bottom"
			>
				<FontAwesomeIcon className="bottom-icon" icon={['fal', 'chevron-down']} size="2x" />
			</Container>
		</div>
	);
};

export default Hero;
