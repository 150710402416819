import { css } from '@emotion/react';

export default theme => css`
	position: relative;
	overflow: hidden;
	transition: ${theme.transitions.create(['background-color'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	})};
	background-color: #161b45;

	&.coloured {
		background-color: #ea5758;
	}

	.homepage-container {
		display: flex;
		align-items: center;
		position: relative;
		background-color: #1F212D;
		min-height: 100vh;
		max-width: 100vw;
		margin: 0;

		${theme.breakpoints.down('md')} {
			min-height: 90vh;
		}

		${theme.breakpoints.down('sm')} {
			min-height: 50vh;
		}
	}

	.container-circle {
		position: absolute;
		top: 50%;
		z-index: 1;

		.circle {
			position: relative;
			background-color: #ea5758;
			opacity: 0.83;
			width: 60vh;
			height: 60vh;
			border-radius: 100%;
		}

		&.container-circle-left {
			left: 0;
		}

		&.container-circle-right {
			right: 0;
		}
	}

	.container-text {
		max-width: 60%;
		margin: auto;
		text-align: center;

		${theme.breakpoints.down('lg')} {
			max-width: 80%;
			padding: ${theme.spacing(10, 2)};
		}

		${theme.breakpoints.down('md')} {
			max-width: 50%;
		}

		${theme.breakpoints.down('sm')} {
			max-width: 80%;
		}
	}

	h4 {
		color: #fff;
		font-family: 'Comfortaa', sans-serif !important;
		font-size: 2rem;
		font-weight: 300;
		opacity: 0.8;
		z-index: 10;

		${theme.breakpoints.down('md')} {
			font-size: 1rem;
		}
	}

	${theme.breakpoints.down('md')} {
		.container-circle {
			.circle {
				width: 40vh;
				height: 40vh;
				max-height: 200px;
				max-width: 200px;
			}
		}
	}
`;
