import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import useGlobals from '../../helpers/hooks/useGlobals';

const HeroLower = ({ text }) => {
	const { window, document } = useGlobals();
	const theme = useTheme();
	const [scrollY, setScrollY] = useState(null);
	const [offsetTop, setOffsetTop] = useState(null);
	const [circleWidth, setCircleWidth] = useState(0);
	const $section = useRef(null);

	const isBrowser = () => ![typeof window, typeof document].includes('undefined');

	const handleResize = () => {
		setCircleWidth($circle.current?.offsetWidth ?? 0);
	};

	const handleScroll = () => {
		isBrowser() && setScrollY(window.scrollY);
		setOffsetTop($section.current?.getBoundingClientRect?.()?.top ?? null);
	};

	useEffect(() => {
		isBrowser() && window.addEventListener('resize', handleResize);
		isBrowser() && window.addEventListener('scroll', handleScroll);
		handleResize();
		handleScroll();

		return () => {
			isBrowser() && window.removeEventListener('resize', handleResize);
			isBrowser() && window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const $circle = useRef(null);
	const _percentScrolled = typeof window === 'undefined' ? 0 : scrollY / window.innerHeight;
	const percentScrolled = _percentScrolled * 1.11111111111;
	const _growScale = percentScrolled > 1 ? percentScrolled ** 8 : 1;
	const growScale = _growScale > 50 ? 50 : _growScale;
	const __opacity = percentScrolled ** 10 - 1;
	const _opacity = percentScrolled > 1 ? (__opacity < 0.83 ? 0.83 : __opacity) : null;
	const opacity = __opacity > 1 ? 1 : _opacity;
	const __ballPosition =
		percentScrolled * (isBrowser() && window.innerWidth / 2) - (circleWidth ?? 0) / 4;
	const _ballPosition =
		percentScrolled < 1 ? __ballPosition : __ballPosition ** (percentScrolled ** 1.5);
	const ballPosition =
		isBrowser() && _ballPosition > window.innerWidth / 2
			? window.innerWidth / 2
			: _ballPosition;

	return (
		<div id="hero-lower" ref={$section} css={styles(theme)}>
			<Box className="container-circle container-circle-left">
				<Box
					ref={$circle}
					className="circle"
					style={{
						opacity,
						left: `${ballPosition}px`,
						transform: `scale(${growScale}) translateY(calc(-50% / ${growScale})) translateX(calc(-50% / ${growScale}))`
					}}
				/>
			</Box>
			<Box className="container-circle container-circle-right">
				<Box
					className="circle"
					style={{
						opacity,
						right: `${ballPosition}px`,
						transform: `scale(${growScale}) translateY(calc(-50% / ${growScale})) translateX(calc(50% / ${growScale}))`
					}}
				/>
			</Box>

			<Container className="homepage-container">
				<Typography component={'h4'} className="container-text">
					{text}
				</Typography>
			</Container>
		</div>
	);
};

export default HeroLower;
