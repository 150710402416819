import { css as styled } from '@emotion/react';

const styling = theme => styled`

	@import url('http://fonts.cdnfonts.com/css/butler');
	min-height: 100vh;
	display: flex;
	align-items: center;
	align-content: center;
	text-align: center;

	.hero-move-up {
		transform: translateY(-35px);
	}

	.hero {
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
		max-width: 800px;
		&.hero-inline {
				display: flex;
				align-content: center;
				align-items: center;
		}

		.hero-logo {
			max-width: 300px;
			width: 80%;
			position: relative;
			padding: ${theme.spacing(0, 4)};

			${theme.breakpoints.down('sm')} {
				width: 60%;
			}
		}

		.logo-dot {
			font-family: Butler, serif;
			color: #e95659;
			font-size: 5.5rem;
			position: relative;
			top: -24px;
			left: -14px;
			height: 20px;
			user-select: none;

			${theme.breakpoints.down('sm')} {
				left: -6px !important;
			}
		}

		.logo-dot:hover {
			cursor: default;
		}

		h1 {
			justify-content: center;
			font-size: 2.5rem;
			margin: auto;
			margin-bottom: 0;
			font-family: itc-avant-garde-gothic-pro, sans-serif;
			font-weight: 600;
			display: flex;
			line-height: 70px;
			text-align: center;
			max-width: 600px;

			${theme.breakpoints.up(2000)} {
				font-size: 3rem;
			}

			${theme.breakpoints.up('lg')} {
				max-width: 800px;
			}

			${theme.breakpoints.down('md')} {
				font-size: 1.8rem;
				line-height: 2.6rem;
				font-weight: 600;
				width: 60%;
			}

			${theme.breakpoints.down(450)} {
				font-size: 1.5rem;
				line-height: 2.4rem;
				font-weight: 600;
				width: 90%;
			}
		}

		.line-one-fill {
			font-size: 1rem;
			color: transparent;
			text-stroke: 1px #fff;
			-webkit-text-stroke: 1px #fffdf8;
		}

		.line-large {
			width: 60%;
			font-size: 9rem;
			line-height: 130px;
			margin-top: 0;

			${theme.breakpoints.down('md')} {
				font-size: 5rem !important;
				line-height: 80px;
			}

			${theme.breakpoints.down(400)} {
				font-size: 4rem !important;
				line-height: 70px;
			}
		}

		.line-two-fill {
			color: transparent;
			text-stroke: 1px #fffdf8;
			-webkit-text-stroke: 1px #fffdf8;
		}

		.hero-text {
			width: 100%;
		}

		h2 {
			font-size: 3rem;
			line-height: 50px;
			margin: auto;
			margin-top: ${theme.spacing(2)};
			font-family: itc-avant-garde-gothic-pro, sans-serif;
			font-weight: 600;
			width: 100%;

			${theme.breakpoints.down('md')} {
				font-size: 1.8rem;
				line-height: 2.6rem;
				font-weight: 600;
				width: 60%;
			}

			${theme.breakpoints.down(450)} {
				font-size: 1.5rem;
				line-height: 2.4rem;
				font-weight: 600;
				width: 90%;
			}
		}

		h4 {
			opacity: 0.8;
			font-weight: 200;
			line-height: 50px;
		}

		.play {
			font-family: 'Butler';
			color: white;
			font-weight: 200;
			margin-top: -15px;
			padding-left: 50px;
			letter-spacing: -3px;
		}

	}

	.hero-bottom {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: auto;
		bottom: 20px;
		text-align: center;
		max-width: 100%;
		max-height: 50px;
		color: white;
		font-family: itc-avant-garde-gothic-pro, sans-serif;
		z-index: 10;
		
		h4 {
			margin-bottom: 10px;
			font-weight: 300;
			font-size: 21px;
		}
	}

	.bottom-icon {
		max-height: 50px;
	}

	.hero-bottom:hover {
		cursor: pointer;
	}

	.hero-bottom * {
		margin: auto;
	}
`;

export default styling;
